.boldy {
  font-weight: bold;
}

.code-container {
  position: absolute;
  font-family: "Courier New", Courier, monospace;
  padding: 30px;
}

.gallery-container {
  padding-top: 40px;
}

.project-img {
  max-width: 300px !important;
  /* height: 50px; */
  padding: 20px 0px;
}

.subheader {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
}
