.about-container {
  position: relative;
  /* background-color: aqua; */
  font-family: "Courier New", Courier, monospace;
  /* padding: 30px; */
  padding: 0px 40px;
  margin-top: 20vh;
}

.ascii {
  padding-left: 40px;
}

.header {
  /* margin: auto; */
  font-size: x-large;
  color: black;
  /* font-weight: bold; */
}

@media only screen and (max-width: 600px) {
  .about-container {
    margin-top: 10vh;
  }
}
