.cursor {
  color: rgb(0, 255, 0);
}

.matrix-container {
  background-color: black;
  height: 100vh;
  text-align: center;
  /* width: 100vw; */
}

.matrix-text-container {
  padding-top: 50vh;
  background-color: black;
}

.type-text {
  background-color: black;
  color: rgb(0, 255, 0);
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;

  /* background-color: black; */
  /* margin-top: 20px; */
}
